import { CommonModule, NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  ChildrenOutletContexts,
  Data,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { CategoryConstants } from '@bp-core/src/lib/constants/categories';
import {
  ConfigModel,
  GoalModel,
  InvestmentStrategyModel,
  UserCodeTypeEnum,
  UserModel,
} from '@bp-core/src/lib/proto/common-message.pb';
import { GetConfigParam } from '@bp-core/src/lib/proto/config-param.pb';
import { ConfigParamGrpcServiceClient } from '@bp-core/src/lib/proto/config-param.pbsc';
import { PortalUserGrpcServiceClient } from '@bp-core/src/lib/proto/portal-user.pbsc';
import { GetActiveCodesRequest } from '@bp-core/src/lib/proto/user.pb';
import { UserGrpcServiceClient } from '@bp-core/src/lib/proto/user.pbsc';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { environment } from '@environment/environment';
import { fuseAnimations } from '@fuse/animations';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';

import { PortalInsuranceGrpcServiceClient } from '@bp-core/src/lib/proto/portal-insurance.pbsc';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GrpcCoreModule } from '@ngx-grpc/core';
import { Empty } from '@ngx-grpc/well-known-types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BpUserComponent } from 'bp/layout/common/bp-user/bp-user.component';
import { BpCodes } from 'bp/shared/models/config-code.model';
import { BpHeaderConfig } from 'bp/shared/models/header-config';
import { BpHeaderService } from 'bp/shared/services/bp-header.service';
import { PortalConfigParamService } from 'bp/shared/services/portal-config-param.service';
import { UtilityService } from 'bp/shared/services/utility-service.service';
import { combineLatest, filter, Subject, take, takeUntil } from 'rxjs';
import { BpVerticalNavigationComponent } from '../components/vertical/vertical.component';

@Component({
  selector: 'bp-layout',
  templateUrl: './bp.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .bg-office {
        background-color: #363636 !important;
      }
      .bg-endow {
        background-color: #ffffff !important;
      }
      .bg-normal {
        background-color: #27272a !important;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FuseLoadingBarComponent,
    MatButtonModule,
    RouterOutlet,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    BpUserComponent,
    NgClass,
    GrpcCoreModule,
    BpVerticalNavigationComponent,
  ],
  animations: fuseAnimations,
})
export class BpLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  env = environment;
  isScreenSmall: boolean;
  navigation: Navigation;
  user: UserModel | undefined;
  shortTermInvestGoal: GoalModel | undefined;
  shortDollarsInvestmentGoal: GoalModel;
  goals: GoalModel[] | undefined;
  cajaMonedaEnable = false;
  userHasVectorEntity = false;
  fileUrlBase = environment.urlApiV2 + 'api/Files/';
  landingNewUrlContactForm = environment.urlLandingNew + '/contactanos';
  config: ConfigModel;
  token = localStorage.getItem('access_token');
  headerConfig: BpHeaderConfig | null = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  routerUrl: string;
  shortTermInversSavingStrategy: InvestmentStrategyModel | undefined;
  shortTermInversSavingPlusStrategy: InvestmentStrategyModel | undefined;
  lastRouteChildConfigData: Data | undefined;
  @ViewChild('anchorElement') anchorElement: ElementRef;
  shadow: boolean = false;
  animateRoute = true;
  bgSidebar = signal<'normal' | 'office' | 'endow'>('normal');

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private authService: AuthService,
    private _portalInsuranceGrpcServiceClient: PortalInsuranceGrpcServiceClient,
    private portalConfigParamService: PortalConfigParamService,
    private userGrpcServiceClient: UserGrpcServiceClient,
    private configParamGrpcServiceClient: ConfigParamGrpcServiceClient,
    private portalUserGrpcServiceClient: PortalUserGrpcServiceClient,
    private router: Router,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    bpHeaderService: BpHeaderService,
    private contexts: ChildrenOutletContexts,
  ) {
    bpHeaderService.headerConfig$.subscribe(config => {
      setTimeout(() => {
        this.headerConfig = config;
      }, 0);
    });
  }
  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          this.shadow = !entry.isIntersecting;
        });
      },
      { threshold: 0.1 },
    );

    observer.observe(this.anchorElement.nativeElement);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  /**
   * Getter for know if is sceensmall and the route has the HamburguerBtnOnResponsive config
   */
  get showHamburguerOnDefaultLeading(): boolean {
    if (this.isScreenSmall) {
      return this.lastRouteChildConfigData?.showHamburguerBtnOnResponsive;
    } else {
      return true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.router.events
      .pipe(
        // Filtra solo los eventos de finalización de navegación
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        // Reinicia la animación
        this.animateRoute = false;
        setTimeout(() => (this.animateRoute = true), 0);
      });
    this.getLastRouteChildConfigData();
    this.routerUrl = this.router.url;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(val => {
      this.routerUrl = (val as NavigationEnd).url;
      this.getLastRouteChildConfigData();
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$.pipe(take(1)).subscribe(({ matchingAliases }) => {
      // Check if the screen is small
      this.isScreenSmall = !matchingAliases.includes('md');
    });

    // Create caja moneda config Request
    const cajaMonedaconfigParamRequest = new GetConfigParam();
    cajaMonedaconfigParamRequest.code = 'ENABLE-USDBOX';

    // Create bpus active codes request
    const activeCodesrequest = new GetActiveCodesRequest();
    activeCodesrequest.type = UserCodeTypeEnum.BP_US_INVITATION;

    combineLatest([
      this.authService.userSubject$, // user
      this.userGrpcServiceClient.getActiveCodes(activeCodesrequest), // codes
      this.configParamGrpcServiceClient.get(cajaMonedaconfigParamRequest), // cajaMonedaConfig
      this.portalUserGrpcServiceClient.getConfig(new Empty()), // config (this is for get the some footer url files)
      this._fuseMediaWatcherService.onMediaChange$, // matchingAliases
      this.portalConfigParamService.get(BpCodes.newSidebarItems),
    ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        ([user, codes, cajaMonedaConfig, config, { matchingAliases }, newSidebarItems]) => {
          // GET DEFAULT NAV
          this._navigationService.get().subscribe(navigation => {
            this.user = user;
            this.goals = user?.goals;
            this.config = config;
            this.isScreenSmall = !matchingAliases.includes('md');

            if (cajaMonedaConfig?.valueType == 'BOOL') {
              this.cajaMonedaEnable = cajaMonedaConfig?.value == 'true';
            }

            this.userHasVectorEntity = this.searchIfUserHasFinancialEntity('vector');

            // DECIDE IF ADD BPUS ITEM
            codes.values?.forEach(BpUSinvitation => {
              if (this.userHasVectorEntity && BpUSinvitation.status.length > 0) {
                navigation.bp.push({
                  id: 'bpUs',
                  title: 'BetterplanUS',
                  type: 'basic',
                  icon: 'heroicons_solid:chevron-up',
                  classes: { icon: this.formatFaIcon('fa-flag-usa') },
                  link: '/ibkr/activate',
                });
              }
            });

            // agregar sociedades
            navigation.bp.push({
              id: 'entity',
              title: 'Sociedades',
              type: 'basic',
              icon: 'heroicons_outline:briefcase',
              link: '/entity/activate',
            });

            // DECIDE IF ADD AHORRO GROUP
            if (this.userHasVectorEntity && !this.isScreenSmall) {
              // Search for short-Term-Invest, short-Term-Invest-Plus, short-Term-Invest-Dollar goals on the user
              this.shortTermInvestGoal = this.searchGoalByCategory('short-term-invest');
              let shortTermInvestPlusGoal = this.searchGoalByCategory('short-term-invest-plus');
              let shortTermInvestDollarGoal = this.searchGoalByCategory(
                'short-term-invest-dollars',
              );
              let shortTermInvestDolarPlusGoal = this.searchGoalByCategory(
                'short-term-invest-dollars-plus',
              );
              //verificamos que las metas de ahorro tenga capital
              if (this.shortTermInvestGoal && this.shortTermInvestGoal.currentCapital === 0) {
                this.shortTermInvestGoal = undefined;
              }
              if (shortTermInvestPlusGoal && shortTermInvestPlusGoal.currentCapital === 0) {
                shortTermInvestPlusGoal = undefined;
              }
              if (shortTermInvestDollarGoal && shortTermInvestDollarGoal.currentCapital === 0) {
                shortTermInvestDollarGoal = undefined;
              }
              if (
                shortTermInvestDolarPlusGoal &&
                shortTermInvestDolarPlusGoal.currentCapital === 0
              ) {
                shortTermInvestDolarPlusGoal = undefined;
              }

              // Add group
              navigation.bp.push({
                id: 'ahorro',
                title: 'Metas',

                type: 'group',
                children: [
                  {
                    id: 'ahorro.cuenta-ahorro',
                    title: 'Cuenta Ahorro',
                    type: 'basic',
                    icon: 'mat_outline:savings',
                    link: this.shortTermInvestGoal
                      ? '/goals/' + this.shortTermInvestGoal.id
                      : undefined,
                    fragment: this.shortTermInvestGoal ? 'summary' : undefined,
                  },
                  {
                    id: 'ahorro.cuenta-ahorro-plus',
                    title: 'Cuenta Ahorro Plus',
                    type: 'basic',
                    icon: 'mat_outline:savings',
                    link: shortTermInvestPlusGoal
                      ? '/goals/' + shortTermInvestPlusGoal.id
                      : undefined,
                    fragment: shortTermInvestPlusGoal ? 'summary' : undefined,
                  },

                  {
                    id: 'ahorro.cuenta-ahorro-dolar',
                    title: 'Cuenta Ahorro Dólar',
                    type: 'basic',
                    icon: 'mat_outline:savings',
                    link: shortTermInvestDollarGoal
                      ? '/goals/' + shortTermInvestDollarGoal.id
                      : undefined,
                    fragment: shortTermInvestDollarGoal ? 'summary' : undefined,
                  },
                  {
                    id: 'ahorro.cuenta-ahorro-dolar-plus',
                    title: 'Cuenta Dólar Plus',
                    type: 'basic',
                    icon: 'mat_outline:savings',
                    link: shortTermInvestDolarPlusGoal
                      ? '/goals/' + shortTermInvestDolarPlusGoal.id
                      : undefined,
                    fragment: shortTermInvestDolarPlusGoal ? 'summary' : undefined,
                  },
                ],
              });
              // quitamos las metas de ahorro que no esten activas
              const ahorroSection = navigation.bp.find(e => e.id === 'ahorro');

              if (ahorroSection && ahorroSection.children) {
                ahorroSection.children = ahorroSection.children.filter(goal => goal.link);
              }
            }

            // DECIDE IF ADD INVERSION GROUP
            if (!this.isScreenSmall) {
              // Filter use goals to get the normal inversion goals
              const filteredNormalGoals = this?.goals?.filter(
                element =>
                  !element.archived &&
                  element?.goalCategory?.code !== CategoryConstants.wallet &&
                  !CategoryConstants.savingCategoryCodes.includes(element?.goalCategory?.code!) &&
                  // filtar metas que no tengan capital
                  element.currentCapital !== 0,
              );

              filteredNormalGoals?.sort((a, b) =>
                a.starred === b.starred ? 0 : a.starred ? -1 : 1,
              );

              // Add group
              navigation.bp.push({
                id: 'inversion',

                classes: {
                  title: 'h-0',
                  wrapper: 'h-0 ',
                },
                type: 'group',
                children: filteredNormalGoals
                  ? filteredNormalGoals.map(g => {
                      const { icon, classes } = this.getIconOrClass(
                        g.icon ? g.icon.code : g.goalCategory?.icon?.code,
                      );

                      return {
                        id: 'inversion.goal-' + g.id,
                        title: g.title,
                        type: 'basic',
                        icon,
                        classes: { icon: classes },
                        link: '/goals/' + g.id,
                        fragment: 'summary',
                      };
                    })
                  : [],
              });
              const cajaMonedaUSDGoal = this.searchGoalByCategoryAndCurrency('caja-moneda', 'USD');
              const cajaMonedaCLPGoal = this.searchGoalByCategoryAndCurrency('caja-moneda', 'CLP');

              // DECIDE IF ADD billetera-dolar NAV ITEM
              if (
                this.cajaMonedaEnable &&
                cajaMonedaUSDGoal &&
                cajaMonedaUSDGoal.currentCapital !== 0
              ) {
                // Search for caja-Moneda goal on the user

                // Add nav item to ahorro group
                navigation.bp
                  .find(e => e.id === 'inversion')
                  ?.children?.push({
                    id: 'ahorro.billetera-dolar',
                    title: 'Billetera Dólar',
                    type: 'basic',
                    icon: 'mat_solid:local_atm',
                    link: cajaMonedaUSDGoal
                      ? '/goals/' + cajaMonedaUSDGoal.id
                      : '/dollarbox/activate',
                    fragment: cajaMonedaUSDGoal ? 'summary' : undefined,
                  });
              }

              // DECIDE IF ADD billetera-dolar NAV ITEM
              if (
                this.cajaMonedaEnable &&
                cajaMonedaCLPGoal &&
                cajaMonedaCLPGoal.currentCapital !== 0
              ) {
                // Search for caja-Moneda goal on the user

                // Add nav item to ahorro group
                navigation.bp
                  .find(e => e.id === 'inversion')
                  ?.children?.push({
                    id: 'ahorro.billetera-pesos',
                    title: 'Billetera Pesos',
                    type: 'basic',
                    icon: 'mat_solid:local_atm',
                    link: '/goals/' + cajaMonedaCLPGoal.id,
                    fragment: cajaMonedaCLPGoal ? 'summary' : undefined,
                  });
              }
              // add new goal item
              navigation.bp
                .find(e => e.id === 'inversion')
                ?.children?.push({
                  id: 'inversion.nueva-meta',
                  title: 'Nueva Meta',
                  type: 'basic',
                  icon: 'add',
                  classes: { wrapper: 'cg-sidebar-item-btn-go-to-flow' },
                  function: () => {
                    this.utilityService.navigateToNewGoal();
                  },
                });
            }

            // DECIDE IF ADD RESPONSIVE ITEMS
            if (this.isScreenSmall) {
              navigation.bp.push(
                {
                  id: 'activty',
                  title: 'Actividad',
                  type: 'basic',
                  icon: 'heroicons_outline:queue-list',
                  link: '/dashboard/activities',
                },
                {
                  id: 'referrals',
                  title: 'Invita y gana',
                  type: 'basic',
                  icon: 'heroicons_outline:gift',
                  function: () => {
                    this.utilityService.navigateToReferral();
                  },
                },
                {
                  id: 'new-goal',
                  title: 'Agregar nueva',
                  type: 'basic',
                  icon: 'add',
                  function: () => {
                    this.utilityService.navigateToNewGoal();
                  },
                },
              );
            }

            if (user?.insuranceContracts?.length! > 0) {
              // add Seguros to bp nav
              navigation.bp.push({
                id: 'seguros',
                title: 'Seguros',
                type: 'group',
                children: user?.insuranceContracts?.map(contract => ({
                  id: 'seguros.' + contract.id,
                  title: contract.product?.title,
                  type: 'basic',
                  icon: 'heroicons_outline:shield-check',
                  link: '/insurances/' + contract.id,
                })),
              });
            }
            if (newSidebarItems && newSidebarItems !== '') {
              const newSidebarItemsArray = newSidebarItems.split(',');
              const newBadge = {
                title: 'Nuevo',
                classes: 'px-2 bg-primary text-white rounded-full',
              };
              newSidebarItemsArray.forEach(item => {
                const sidebarItem = navigation.bp.find(e => e.id === item);
                if (sidebarItem) {
                  sidebarItem.badge = newBadge;
                }
              });
            }

            // SET NAVIGATION
            this.navigation = navigation;
          });
        },
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<BpVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * OPEN INTERCOM CHAT
   *
   * @param {MouseEvent} $event
   * @memberof BpLayoutComponent
   */
  openIntercomChat($event: MouseEvent) {
    $event.preventDefault();
    try {
      (window as any).Intercom('show');
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * NAVIGATE TO THE /dashboard
   *
   * @memberof BpLayoutComponent
   */
  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }
  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  /**
   * GET A FORMATED CLASS FOR MAKE FA-ICONS COMPATIBLE WITH FUSE NAV-BAR
   * (this fa-icons dont exits in fuse)
   *
   * @param {string} faIcon
   * @return {*}
   * @memberof BpLayoutComponent
   */
  formatFaIcon(faIcon: string) {
    let faIconClass = '';
    const faSplitIcon = faIcon.split('-');
    switch (faSplitIcon[0]) {
      case 'fab fa':
        faIconClass = 'bp-fa-icon ' + faIcon;
        break;
      case 'fa':
        faIconClass = 'bp-fa-icon fa ' + faIcon;
        break;
    }
    return faIconClass;
  }

  /**
   * RETURN THE EQUIVALENT FUSE ICON OF A FA-ICON OR THE SAME FA-ICON WITH A SPECIAL CLASS
   *
   * @param {string} [portalIcon]
   * @return {*}  {{ icon: string; classes: string }}
   * @memberof BpLayoutComponent
   */
  getIconOrClass(portalIcon?: string): { icon: string; classes: string } {
    if (!portalIcon) return { icon: 'mat_outline:help_outline', classes: '' };

    const fuseIcon = {
      'fa-baby': 'mat_outline:escalator_warning',
      'fa-user-graduate': 'heroicons_outline:academic-cap',
      'fa-piggy-bank': 'mat_outline:savings',
      'fa-gift': 'heroicons_outline:gift',
      'fa-home': 'heroicons_outline:home',
      'fa-umbrella': 'feather:umbrella',
      'fa-user-tie': 'heroicons_outline:briefcase',
      'fa-heart': 'heroicons_outline:heart',
      'fa-arrow-up': 'feather:arrow-up-circle',
      'fa-umbrella-beach': 'mat_solid:beach_access',
      'fa-arrow-circle-up': 'feather:arrow-up-circle',
      'fa-users': 'heroicons_outline:user-group',
      'fa-biking': 'mat_outline:directions_bike',
      'fa-plane-departure': 'mat_outline:flight_takeoff',
      'fa-graduation-cap': 'heroicons_outline:academic-cap',
      'fa-mountain': 'mat_outline:landscape',
      'fa-university': 'heroicons_outline:building-library',
    };

    if (Object.keys(fuseIcon).includes(portalIcon)) {
      return { icon: fuseIcon[portalIcon], classes: '' };
    } else {
      // Icon always has to have content
      // this icon is goin to has d-none
      return {
        icon: 'heroicons_solid:chevron-up',
        classes: this.formatFaIcon(portalIcon) || 'fa-regular fa-circle',
      };
    }
  }

  /**
   * SEARCH IN THE USER GOAL FOR GOAL WITH A category.code
   *
   * @param {string} category
   * @return {*}  {(GoalModel | undefined)}
   * @memberof BpLayoutComponent
   */
  searchGoalByCategory(
    category: string,
    strategy?: InvestmentStrategyModel,
  ): GoalModel | undefined {
    if (strategy) {
      return this.goals?.find(
        goal =>
          goal?.goalCategory?.code === category &&
          goal.portfolio?.investmentStrategyId === strategy.id,
      );
    } else {
      return this.goals?.find(goal => goal?.goalCategory?.code === category);
    }
  }

  /**
   * SEARCH IN THE USER GOAL FOR GOAL WITH A category.code and currency code
   *
   * @param {string} category
   * @param {string} currencyCode
   * @return {*}  {(GoalModel | undefined)}
   * @memberof BpLayoutComponent
   */
  searchGoalByCategoryAndCurrency(category: string, currencyCode: string): GoalModel | undefined {
    return this.goals?.find(
      goal => goal?.goalCategory?.code === category && goal.currency?.currencyCode === currencyCode,
    );
  }

  /**
   * SEARCH IN THE userFinancialEntities FOR A ENTITY WITH A uuid AND active
   *
   * @param {string} financialEntityUuid
   * @return {*}  {boolean}
   * @memberof BpLayoutComponent
   */
  searchIfUserHasFinancialEntity(financialEntityUuid: string): boolean {
    return this.user?.userFinancialEntities?.find(
      f => f.financialEntity?.uuid === financialEntityUuid && f.status === 'active',
    )
      ? true
      : false;
  }

  /**
   * Set lastRouteChildConfigData with the actual rooute data
   *
   * @memberof BpLayoutComponent
   */
  getLastRouteChildConfigData() {
    let lastRouteChild = this.activatedRoute.snapshot;

    while (lastRouteChild.firstChild) {
      lastRouteChild = lastRouteChild.firstChild;
    }

    this.lastRouteChildConfigData = lastRouteChild.routeConfig?.data;
  }
}
