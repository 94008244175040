import * as am5 from '@amcharts/amcharts5';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeESCL from '@angular/common/locales/es-CL';
import localeESCLExtra from '@angular/common/locales/extra/es-CL';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  ErrorHandler,
  LOCALE_ID,
  Provider,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LogRocketErrorHandler } from '@bp-core/src/lib/utils/log-rocket-error-handler';
import { environment } from '@environment/environment';
import { provideFuse } from '@fuse';
import Hotjar from '@hotjar/browser';
import { GrpcMessage } from '@ngx-grpc/common';
import { GRPC_INTERCEPTORS, GrpcCoreModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import * as Sentry from '@sentry/angular-ivy';
import { replayCanvasIntegration, replayIntegration } from '@sentry/browser';
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { IPasswordStrengthMeterService } from 'angular-password-strength-meter';
import { Angulartics2Module } from 'angulartics2';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { bpAppRoutes, notMatchRedirectRoutes } from 'bp/app.routing';
import { BpPendingNotificationsService } from 'bp/shared/services/bp-pending-notifications.service';
import { ComponentFactoryService } from 'bp/shared/services/component-factory.service';
import { UserStateService } from 'bp/shared/services/user-state.service';
import { register as registerSwiperElements } from 'swiper/element/bundle';
// import { H } from 'highlight.run';

import { GrpcLoggerModule } from '@bp-core/src/lib/modules/grpc-logger/grpc-logger.module';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { provideLumberjack } from '@ngworker/lumberjack';
import { provideLumberjackConsoleDriver } from '@ngworker/lumberjack/console-driver';
import LogRocket from 'logrocket';
import * as mixpanel from 'mixpanel-browser';
import { provideClarity } from 'ngx-clarity';
import { MarkdownModule } from 'ngx-markdown';
import { OAuthModule } from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/public_api';
import { AuthGrpcInterceptor } from 'projects/bp-core/src/lib/services/portal/auth-grpc.interceptor';
import { GrpcLoadingInterceptor } from 'projects/bp-portal/src/bp/shared/interceptors/grpc-loading.interceptor';
import { BpLoadingInterceptor } from 'projects/bp-portal/src/bp/shared/interceptors/loading.interceptor';
import { AppInteractionService } from '../../bp-core/src/lib/services/app-interaction.service';
import { AppComponent } from './app/app.component';
import { CustomPsmServiceService } from './app/customPsmService.service';
import { DevelopErrorHandler } from './app/develop-error-handler.service';
import { mockApiServices } from './app/mock-api';
import { BpHeaderService } from './bp/shared/services/bp-header.service';
import { PortalConfigParamService } from './bp/shared/services/portal-config-param.service';
registerSwiperElements();

am5.addLicense(environment.am5chartLicense);
const portalRoutes = [...bpAppRoutes, ...notMatchRedirectRoutes];

const providers: (EnvironmentProviders | Provider)[] = [
  provideClarity({
    enabled: environment.name == 'Production',
    projectId: 'hrh0x7c9ow',
  }),
  { provide: GRPC_INTERCEPTORS, useClass: GrpcLoadingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BpLoadingInterceptor,
    multi: true,
  },
  {
    provide: IPasswordStrengthMeterService,
    useClass: CustomPsmServiceService,
  },
  // CustomRouter,
  // {
  //   provide: Router,
  //   useClass: CustomRouter,
  // },
  /*{
    provide: FUSE_CONFIG,
    useValue: appConfig,
  },*/
  {
    provide: LOCALE_ID,
    useValue: 'es-CL',
  },
  {
    provide: GRPC_INTERCEPTORS,
    useClass: AuthGrpcInterceptor,
    multi: true,
  },
  {
    provide: DateAdapter,
    useClass: LuxonDateAdapter,
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'D',
      },
      display: {
        dateInput: 'DDD',
        monthYearLabel: 'LLL yyyy',
        dateA11yLabel: 'DD',
        monthYearA11yLabel: 'LLLL yyyy',
      },
    },
  },

  // Transloco Config
  provideTransloco(),

  // Fuse
  provideAuth(),
  provideIcons(),
  provideFuse({
    mockApi: {
      delay: 0,
      services: mockApiServices,
    },
    fuse: {
      layout: 'classy',
      scheme: 'light',
      screens: {
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1440px',
      },
      theme: 'theme-bp',
      themes: [
        {
          id: 'theme-bp',
          name: 'Betterplan',
        },
        {
          id: 'theme-default',
          name: 'Default',
        },
        {
          id: 'theme-brand',
          name: 'Brand',
        },
        {
          id: 'theme-teal',
          name: 'Teal',
        },
        {
          id: 'theme-rose',
          name: 'Rose',
        },
        {
          id: 'theme-purple',
          name: 'Purple',
        },
        {
          id: 'theme-amber',
          name: 'Amber',
        },
      ],
    },
  }),
  BpPendingNotificationsService,
  UserStateService,
  AppInteractionService,
  ComponentFactoryService,
  BpHeaderService,
  PortalConfigParamService,
  provideLumberjack(),
  provideLumberjackConsoleDriver(),
];
const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  enableViewTransitions: true,
  enableTracing: false,
};

if (environment.name == 'Production') {
  // H.init('zg0xprng', {
  //   environment: environment.name,
  //   version: environment.version,
  //   reportConsoleErrors: true,
  //   serviceName: 'bp-portal',
  //   privacySetting: 'none',
  //   enableCanvasRecording: true,
  //   enablePerformanceRecording: true,
  //   tracingOrigins: [
  //     window.location.origin,
  //     /^https:\/\/[^/]+\.betterplan\.cl\/.*/,
  //     /^https:\/\/[^/]+\.fintoc\.com\/.*/,
  //   ],
  //   networkRecording: {
  //     enabled: true,
  //     recordHeadersAndBody: true,
  //     urlBlocklist: [
  //       // insert full or partial urls that you don't want to record here
  //       // Out of the box, Highlight will not record these URLs (they can be safely removed):
  //       'https://www.googleapis.com/identitytoolkit',
  //       'https://securetoken.googleapis.com',
  //     ],
  //   },
  // });
  datadogRum.init({
    applicationId: 'aec60ba0-25e5-4728-a513-40ce11026f64',
    clientToken: 'pub0779bf1b47d1927d2a3084dfb0f90459',
    site: 'us3.datadoghq.com',
    service: 'bp-client-portal',
    env: environment.name,
    version: environment.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      window.location.origin,
      /^https:\/\/[^/]+\.betterplan\.cl\/.*/,
      /^https:\/\/[^/]+\.fintoc\.com\/.*/,
    ],
  });
  datadogLogs.init({
    clientToken: 'pub0779bf1b47d1927d2a3084dfb0f90459',
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: environment.name,
    version: environment.version,
    service: 'bp-client-portal',
    forwardConsoleLogs: 'all',
  });
  Sentry.init({
    dsn: 'https://f9d58ed9a04b413ab245136d03d69b57@o297776.ingest.sentry.io/1765951',
    debug: !environment.production,
    release: environment.version,
    environment: environment.name,
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    tracePropagationTargets: [/^https:\/\/[^/]+\.betterplan\.cl\/.*/],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      replayIntegration({
        networkDetailAllowUrls: [
          window.location.origin,
          /^https:\/\/[^/]+\.betterplan\.cl\/.*/,
          /^https:\/\/[^/]+\.fintoc\.com\/.*/,
        ],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
      }),
      replayCanvasIntegration(),
      httpClientIntegration(),
      //  Sentry.browserProfilingIntegration(),
      Sentry.metrics.metricsAggregatorIntegration(),
      captureConsoleIntegration({
        levels: [
          /*'log', 'info', 'warn','debug','error', 'assert'*/
        ],
      }),
      Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    profilesSampleRate: 1,
    sendDefaultPii: false,
  });
  LogRocket.init('fswggp/bp', {
    release: `${environment.version}-${environment.name}`,
  });
  Hotjar.init(3637239, 6);
  mixpanel.init('eb138694af8b89f7710564352bad1a48', {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
  });
  providers.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: ErrorHandler,
      useClass: LogRocketErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  );
} else {
  providers.push({ provide: ErrorHandler, useClass: DevelopErrorHandler });
}
registerLocaleData(localeESCL, localeESCLExtra);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RouterModule.forRoot(portalRoutes, routerConfig),
      // Fuse, FuseConfig & FuseMockAPI
      // 3rd party modules that require global configuration via forRoot
      Angulartics2Module.forRoot(),
      OAuthModule.forRoot(),
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      GoogleTagManagerModule.forRoot({
        id: 'GTM-N3S8WSB',
      }),
      MarkdownModule.forRoot({}),
      GrpcCoreModule.forRoot(),
      GrpcWebClientModule.forRoot({
        settings: {
          host: environment.urlGrpc,
          format: 'binary',
          withCredentials: false,
          suppressCorsPreflight: false,
        },
      }),
      GrpcLoggerModule.forRoot({
        settings: {
          // enables logger in dev mode and still lets you see them in production when running `localStorage.setItem('logger', 'true') in the console`
          // enabled:
          //   localStorage.getItem('logger') === 'true' || !environment.production,
          enabled: true,
          // protobuf json is more human-readable than the default toObject() mapping
          // please beware: if you use google.protobuf.Any you must pass the proper `messagePool` argument
          requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
          responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        },
      }),
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      MatSnackBarModule,

      // AppInsightsModule.forRoot({
      //   instrumentationKey: environment.appInsightsInstrumentationKey,
      // }),
    ),
    provideAnimations(),
    ...providers,
  ],
}).catch(err => console.error(err));
